import { useCallback, useEffect, useState } from 'react'
import { useReactOidc } from '@axa-fr/react-oidc-context'

import { defaultConnectedUser } from '../models/ConnectedUser'
import Right from '../models/Right'
import { getUser } from '../services/UserService'

interface UserPermissionsCheck {
  hasPermission: (right: Right) => boolean
}

const useUserPermissions = (): UserPermissionsCheck => {
  const { oidcUser } = useReactOidc()

  const [user, setUser] = useState(defaultConnectedUser)
  useEffect(() => {
    getUser(oidcUser).then((user) => {
      setUser(user)
    })
  }, [oidcUser])

  const hasPermission = useCallback(
    (right: Right) => {
      return Boolean(user?.rights.includes(right))
    },
    [user]
  )
  return { hasPermission }
}

export default useUserPermissions
