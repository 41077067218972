import { useEffect, useState } from 'react'
import { NavLink, useLocation } from 'react-router-dom'
import { useReactOidc } from '@axa-fr/react-oidc-context'
import { FormField, SideNavigation, Tree } from '@myeh/design-system'
import classNames from 'classnames'

import { defaultConnectedUser } from '../../../models/ConnectedUser'
import Right from '../../../models/Right'
import { featureToggle } from '../../../services/FeatureToggle'
import { getUser } from '../../../services/UserService'

import style from './ContentPage.module.scss'

const buildDatasource = (to = '', path = '/') => ({
  props: {
    as: NavLink,
    to,
    className: 'navigation-menu-item'
  },
  selected: path.startsWith(to) && to.includes(path)
})

const environments = [
  { value: 'production', label: 'Production' },
  { value: 'int', label: 'int' },
  { value: 'uatr', label: 'uatr' },
  { value: 'uatm', label: 'uatm' }
]

export const ContentPage = ({ children }) => {
  const [env, setEnv] = useState(null)
  const { pathname } = useLocation() || {}
  const { oidcUser } = useReactOidc()

  const [user, setUser] = useState(defaultConnectedUser)
  useEffect(() => {
    getUser(oidcUser).then((user) => {
      setUser(user)
    })
  }, [oidcUser])
  return (
    <div className={style.page}>
      <header className={style.header}>
        <div className={style['header-content']}>
          <div>
            <h1 className={style.title}>MyEH Admin</h1>
          </div>
          {featureToggle('tmp-environments') && (
            <div className={style['header-env']}>
              <div className={style.label}>Environment</div>
              <div className={style.environments}>
                <FormField.Dropdown
                  appearance='outline'
                  name='env'
                  placeholder='Choose an environment'
                  label=''
                  floatLabel='auto'
                  value={env}
                  items={environments}
                  onChange={setEnv}
                />
              </div>
            </div>
          )}
        </div>
      </header>
      <div className={classNames(style.flex, style.main)}>
        <SideNavigation resizable width={288}>
          <Tree
            datasource={[
              ...(featureToggle('tmp-homepage')
                ? [
                    {
                      label: 'Home',
                      icon: 'product-house',
                      ...buildDatasource('/', pathname)
                    }
                  ]
                : []),
              {
                label: 'Act-as',
                icon: 'user-business-o',
                ...buildDatasource('/act-as', pathname)
              },
              ...(featureToggle('tmp-maintenance-page') &&
              (user.rights.includes(Right.MAINTENANCE_READ) || user.rights.includes(Right.MAINTENANCE_WRITE))
                ? [
                    {
                      label: 'Maintenance page',
                      icon: 'product-excavator',
                      ...buildDatasource('/maintenance', pathname)
                    }
                  ]
                : []),
              ...(featureToggle('tmp-feature-toggles') &&
              (user.rights.includes(Right.TOGGLES_READ) || user.rights.includes(Right.TOGGLES_WRITE))
                ? [
                    {
                      label: 'Feature toggles',
                      icon: 'product-to-do-list',
                      ...buildDatasource('/feature-toggles', pathname)
                    }
                  ]
                : []),
              ...(featureToggle('tmp-partial-disruption') &&
              (user.rights.includes(Right.DISRUPTION_READ) || user.rights.includes(Right.DISRUPTION_WRITE))
                ? [
                    {
                      label: 'Partial disruption messages',
                      icon: 'product-bullhorn',
                      ...buildDatasource('/partial-disruption', pathname)
                    }
                  ]
                : []),
              ...(featureToggle('tmp-messages-per-bu') &&
              (user.rights.includes(Right.DISRUPTION_READ) || user.rights.includes(Right.DISRUPTION_WRITE))
                ? [
                    {
                      label: 'Messages per BU',
                      icon: 'mail-o',
                      ...buildDatasource('/messages-per-bu', pathname)
                    }
                  ]
                : [])
            ]}
          />
          <SideNavigation.Footer showToggle />
        </SideNavigation>
        <div className={style.content}>{children}</div>
      </div>
    </div>
  )
}
