export enum Status {
  ACTIVE = 'Active',
  SCHEDULED = 'Scheduled',
  INACTIVE = 'Inactive'
}

export enum Type {
  GENERIC = 'generic',
  DMS = 'dms',
  CREDIT_LIMIT = 'creditLimit',
  SMARTCOVER = 'smartcover',
  GRADE = 'grade',
  NDCOVER = 'ndCover',
  POLICIES = 'policies',
  USER_RIGHTS = 'userRights',
  COMPANY_SEARCH = 'companySearch',
  COVER_HISTORYV2 = 'coverHistoryV2',
  GENERAL_LATENCY = 'generalLatency',
  SUPPORT_TICKETS = 'supportTickets',
  UPCOMING_MAINTENANCE = 'upcomingMaintenance'
}

export function stringToType(type: string): Type {
  switch (type) {
    case Type.DMS:
      return Type.DMS
    case Type.CREDIT_LIMIT:
      return Type.CREDIT_LIMIT
    case Type.SMARTCOVER:
      return Type.SMARTCOVER
    case Type.GRADE:
      return Type.GRADE
    case Type.NDCOVER:
      return Type.NDCOVER
    case Type.POLICIES:
      return Type.POLICIES
    case Type.USER_RIGHTS:
      return Type.USER_RIGHTS
    case Type.COMPANY_SEARCH:
      return Type.COMPANY_SEARCH
    case Type.COVER_HISTORYV2:
      return Type.COVER_HISTORYV2
    case Type.GENERAL_LATENCY:
      return Type.GENERAL_LATENCY
    case Type.SUPPORT_TICKETS:
      return Type.SUPPORT_TICKETS
    case Type.UPCOMING_MAINTENANCE:
      return Type.UPCOMING_MAINTENANCE
    default:
      return Type.GENERIC
  }
}
export function getMessageFromType(type: Type): string {
  switch (type) {
    case Type.DMS:
      return 'The current situation might prevent you from correctly accessing your most up to date information. Our teams are dedicated to the resolution of the issue as soon as possible. Thank you for your understanding.'
    case Type.CREDIT_LIMIT:
      return 'The current situation might prevent you from correctly accessing your credit limit services. Our teams are dedicated to the resolution of the issue as soon as possible. Thank you for your understanding.'
    case Type.SMARTCOVER:
      return 'The current situation might prevent you from correctly accessing your CIO services. Our teams are dedicated to the resolution of the issue as soon as possible. Thank you for your understanding.'
    case Type.GRADE:
      return 'The current situation might prevent you from correctly accessing your grade services. Our teams are dedicated to the resolution of the issue as soon as possible. Thank you for your understanding.'
    case Type.NDCOVER:
      return 'The current situation might prevent you from correctly accessing your ND cover services. Our teams are dedicated to the resolution of the issue as soon as possible. Thank you for your understanding.'
    case Type.POLICIES:
      return 'The current situation might prevent you from correctly accessing your policy portfolio. Our teams are dedicated to the resolution of the issue as soon as possible. Thank you for your understanding.'
    case Type.USER_RIGHTS:
      return 'The current situation might prevent you from correctly accessing your different services. Our teams are dedicated to the resolution of the issue as soon as possible. Thank you for your understanding.'
    case Type.COMPANY_SEARCH:
      return 'The current situation might prevent you from correctly accessing the customer search. Our teams are dedicated to the resolution of the issue as soon as possible. Thank you for your understanding.'
    case Type.COVER_HISTORYV2:
      return 'The current situation might prevent you from correctly accessing history information. Our teams are dedicated to the resolution of the issue as soon as possible. Thank you for your understanding.'
    case Type.GENERAL_LATENCY:
      return "You may experience some issues with the application's performance. Our teams are currently working to improve the speed and stability of the service. Thank you for your patience and understanding."
    case Type.SUPPORT_TICKETS:
      return 'The current situation might prevent you from correctly creating support requests. Our teams are dedicated to the resolution of the issue as soon as possible. Thank you for your understanding.'
    case Type.UPCOMING_MAINTENANCE:
      return 'Due to maintenance works planned for the upcoming weekend, your access to Allianz Trade Online might be disrupted during this period of time.'
    default:
      return 'The current situation might prevent you from correctly accessing your information. Our teams are dedicated to the resolution of the issue as soon as possible. Thank you for your understanding.'
  }
}

interface Message {
  applicationId: string
  id: string
  type: Type
  message: string
  startDateTime: Date
  endDateTime?: Date
  status: Status
  hasFallback?: boolean
  buCode?: string
}

export interface EditableMessage {
  id: string
  type: string
  startDateTime: Date
  endDateTime: Date
}

export default Message
