/* eslint-disable @typescript-eslint/no-empty-function */
import { ReactElement, useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router'
import { useReactOidc } from '@axa-fr/react-oidc-context'
import { Button, Link, Spinner } from '@myeh/design-system'

import 'react-datepicker/dist/react-datepicker.css'

import { EditableMessage } from '../../../models/Message'
import { getDisruptionMessage } from '../../../services/PartialDisruptionMessageService'
import { PageTitle } from '../../atoms/PageTitle/PageTitle'
import { SimpleModal } from '../../molecules/SimpleModal/SimpleModal'
import { EditPartialDisruptionMessageForm } from './EditPartialDisruptionMessageForm'

import style from './EditPartialDisruptionMessagePage.module.scss'

export function EditPartialDisruptionMessagePage(): ReactElement {
  const { oidcUser } = useReactOidc()
  const history = useHistory()
  const { id } = useParams<{ id: string }>()
  const [fetchedMessage, setFetchedMessage] = useState<EditableMessage>()
  const [fetching, setFetching] = useState<boolean>(true)
  const [showErrorModal, setShowErrorModal] = useState<boolean>(false)

  useEffect(() => {
    const getMessage = async () => {
      try {
        const response = await getDisruptionMessage(oidcUser, id)
        setFetchedMessage(response)
      } catch (error) {
        setShowErrorModal(true)
      } finally {
        setFetching(false)
      }
    }
    getMessage()
  }, [oidcUser, id])

  return (
    <div>
      <PageTitle title='Edit message' />
      <div className='mh4 mv2'>
        <Link href='/partial-disruption' inline icon='arrow-left' data-testid='back-to-list-link'>
          Back to list
        </Link>
        <h2 className={`${style.title} mt2`}>Partial disruption messages</h2>

        {fetching && (
          <div className={style.spinner}>
            <Spinner size='large' />
          </div>
        )}
        {fetchedMessage && (
          <EditPartialDisruptionMessageForm
            startDateTime={fetchedMessage.startDateTime}
            endDateTime={fetchedMessage.endDateTime}
            type={fetchedMessage.type}
            id={id}
          />
        )}
      </div>
      <SimpleModal
        open={showErrorModal}
        onClose={() => {
          setShowErrorModal(false)
          history.push('/partial-disruption')
        }}
        titleIcon='error'
        title='Technical error'
        body='It seems we are currently facing an issue. We are doing our best so you can access your form. Please try again later.'
        footer={
          <Button
            size='small-medium'
            variant='primary'
            onClick={() => history.push('/partial-disruption')}
            data-testid='ok-button-error-modal'
          >
            Go back
          </Button>
        }
        testId='error-modal'
      />
    </div>
  )
}
