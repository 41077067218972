enum Right {
  TOGGLES_READ = 'admin_read_toggles',
  TOGGLES_WRITE = 'admin_write_toggles',
  MAINTENANCE_READ = 'admin_read_maintenancePage',
  MAINTENANCE_WRITE = 'admin_write_maintenancePage',
  DISRUPTION_READ = 'admin_read_disruption',
  DISRUPTION_WRITE = 'admin_write_disruption',
  ACTAS = 'actas_readonly'
}

export default Right
