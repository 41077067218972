const hexTime = () => {
  const date = new Date()
  return Math.floor(date.getTime() / 1000).toString(16)
}

const generateRandomHex = (size) => [...Array(size)].map(() => Math.floor(Math.random() * 16).toString(16)).join('')

export const generateEhTraceId = () => {
  const version = 1
  const cryptoHex = generateRandomHex(24)
  return `actas=${version}-${hexTime()}-${cryptoHex}`
}
