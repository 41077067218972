const dateFormat = new Intl.DateTimeFormat('fr-FR', {
  year: '2-digit',
  month: 'numeric',
  day: 'numeric'
})
const timeFormat = new Intl.DateTimeFormat('fr-FR', {
  hour: 'numeric',
  minute: 'numeric',
  hour12: false
})

export function formatDateTime(date: Date): string {
  return `${dateFormat.format(date)} ${timeFormat.format(date)}`
}
