import { AxiosResponse } from 'axios'
import { User as OidcUser } from 'oidc-client'

import { tokenAuth } from '../lib/axios'
import User from '../models/ConnectedUser'

export const getContactById = (contactId: string, oidcUser: OidcUser): Promise<AxiosResponse<User>> => {
  const url = `${process.env.REACT_APP_API_BASE_URL}/v1/contacts/${contactId}`

  const authInstance = tokenAuth(oidcUser)

  return authInstance.get(url)
}
