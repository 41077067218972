import { ReactElement, useEffect, useState } from 'react'
import { Column } from 'react-table'
import { useReactOidc } from '@axa-fr/react-oidc-context'
import { Badge, Button, FormField, Icon, Spinner } from '@myeh/design-system'

import { Toggle } from '../../../models/Toggle'
import { exportToggles, getToggles } from '../../../services/MyEHToggle'
import { PageTitle } from '../../atoms/PageTitle/PageTitle'
import Datatable from '../../molecules/Datatable/Datatable'

import style from './FeatureTogglesPage.module.scss'

export function FeatureTogglesPage(): ReactElement {
  const { oidcUser } = useReactOidc()

  const [loading, setLoading] = useState<boolean>(true)
  const [toggles, setToggles] = useState<Array<Toggle>>([])
  const [displayedToggles, setdisplayedToggles] = useState<Array<Toggle>>([])
  const [toggleNameSearchInput, setToggleNameSearchInput] = useState<string>()
  const [statusFilter, setStatusFilter] = useState<string>('all')
  const exportTogglesButton = 'Export'

  const statuses = [
    { value: 'all', label: 'All' },
    { value: 'active', label: 'Active' },
    { value: 'inactive', label: 'Inactive' }
  ]

  useEffect(() => {
    let displayedToggles = toggles
    if (statusFilter !== 'all') {
      const isActive = statusFilter === 'active'
      displayedToggles = toggles.filter((e) => e.value === isActive)
    }
    if (toggleNameSearchInput && toggleNameSearchInput !== '') {
      displayedToggles = displayedToggles.filter((e) => e.name.includes(toggleNameSearchInput as string))
    }
    setdisplayedToggles(displayedToggles as Array<Toggle>)
  }, [toggles, statusFilter, toggleNameSearchInput])

  useEffect(() => {
    async function fetchToggles() {
      try {
        const toggles = await getToggles(oidcUser)
        setToggles(toggles)
        setLoading(false)
      } catch (e) {
        setLoading(false)
      }
    }
    fetchToggles()
  }, [oidcUser])

  const columns: Array<Column<Toggle>> = [
    {
      Header: 'Toggle name',
      accessor: 'name',
      disableSortBy: true
    },
    {
      Header: 'Description',
      accessor: 'description',
      disableSortBy: true
    },
    {
      Header: () => <span className='flex flex-column items-center'>Status</span>,
      accessor: 'value',
      Cell: ({ value }) => (
        <span className='flex flex-column items-center'>
          {value && <Badge type='positive'>Active</Badge>}
          {!value && <Badge type='default'>Inactive</Badge>}
        </span>
      ),
      disableSortBy: true
    }
  ]

  return (
    <div>
      <PageTitle title='Feature toggles' />
      <div className='pa4'>
        <div className='pb4'>
          On this page you will find the list of existing toggles for the environment you are connected to.
          <br />
          This gives more flexibility to teams for activating/deactivating features in the different environments.
        </div>
        {loading ? (
          <div className={style.spinner}>
            <Spinner size='large' />
          </div>
        ) : (
          <>
            <div className='flex items-center justify-between'>
              <div className='w-50 pa2'>
                <FormField.Input
                  appearance='outline'
                  name='toggleNameSearch'
                  label='Toggle Name'
                  onChange={(e) => setToggleNameSearchInput(e.currentTarget.value)}
                  data-testid='toggle-name-search'
                >
                  <FormField.Suffix>
                    <Icon name='search' size='medium' />
                  </FormField.Suffix>
                </FormField.Input>
              </div>
              <div className='w-25 pa2'>
                <FormField.Dropdown
                  appearance='outline'
                  name='statusFilter'
                  placeholder='Status'
                  label='Status'
                  floatLabel='auto'
                  defaultValue='all'
                  items={statuses}
                  onChange={(v) => setStatusFilter(v)}
                  data-testid='toggle-status-filter'
                />
              </div>
              <div className='pa2'>
                <Button
                  variant='secondary'
                  size='small-medium'
                  onClick={() => exportToggles(displayedToggles)}
                  data-testid='export-toggles-button'
                >
                  <Icon name='download' className='desy-mr-2' />
                  {exportTogglesButton}
                </Button>
              </div>
            </div>
            <Datatable<Toggle> data={displayedToggles} columns={columns} isPaginable />
          </>
        )}
      </div>
    </div>
  )
}
