import React, { useEffect, useMemo } from 'react'
import { Column, HeaderGroup, Row, TableOptions, usePagination, useSortBy, useTable } from 'react-table'
import { Icon, Pagination } from '@myeh/design-system'

import style from './Datatable.module.scss'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
interface DatatableProps<T extends Record<string, any>> {
  data: Array<T>
  columns: Array<Column<T>>
  isSortable?: boolean
  isPaginable?: boolean
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function Datatable<T extends Record<string, any>>({
  data,
  columns,
  isSortable,
  isPaginable
}: DatatableProps<T>): React.ReactElement {
  const memoizedColumns = useMemo(() => columns, [columns])
  const memoizedData = useMemo(() => data, [data])

  // react-table configuration
  const plugins = []
  const options: TableOptions<T> = {
    columns: memoizedColumns,
    data: memoizedData
  }
  if (isSortable) {
    plugins.push(useSortBy)
    options.autoResetSortBy = false
  }
  if (isPaginable) {
    plugins.push(usePagination)
  }

  const { getTableProps, getTableBodyProps, headerGroups, rows, page, prepareRow, pageCount, gotoPage, setPageSize } =
    useTable(options, ...plugins)

  useEffect(() => {
    if (isPaginable) {
      setPageSize(10)
    }
  }, [setPageSize, isPaginable])
  const changePage = (value: number) => {
    gotoPage(value - 1)
  }

  const renderHeader = (headerGroup: HeaderGroup<T>) => (
    // eslint-disable-next-line react/jsx-key
    <tr className={`${style.tr}`} {...headerGroup.getHeaderGroupProps()}>
      {headerGroup.headers.map((column) => {
        const thProps = isSortable ? column.getHeaderProps(column.getSortByToggleProps()) : column.getHeaderProps()
        return (
          // eslint-disable-next-line react/jsx-key
          <th className={`${style.th}`} {...thProps}>
            {isSortable && (
              <span className='flex items-center'>
                {!column.disableSortBy &&
                  (column.isSorted ? (
                    column.isSortedDesc ? (
                      <Icon name='chevron-up-small' size='small' />
                    ) : (
                      <Icon name='chevron-down-small' size='small' />
                    )
                  ) : (
                    <Icon name='popout' size='small' />
                  ))}
                {column.render('Header')}
              </span>
            )}
            {!isSortable && column.render('Header')}
          </th>
        )
      })}
    </tr>
  )
  const renderRow = (row: Row<T>) => {
    prepareRow(row)
    return (
      // eslint-disable-next-line react/jsx-key
      <tr className={style.tr} {...row.getRowProps()}>
        {row.cells.map((cell) => {
          return (
            // eslint-disable-next-line react/jsx-key
            <td className={style.td} {...cell.getCellProps()}>
              {cell.render('Cell')}
            </td>
          )
        })}
      </tr>
    )
  }

  return (
    <>
      <table {...getTableProps()} className={style.table}>
        <thead>{headerGroups.map(renderHeader)}</thead>
        <tbody {...getTableBodyProps()}>
          {!isPaginable && rows.map(renderRow)}
          {isPaginable && page.map(renderRow)}
        </tbody>
      </table>
      {isPaginable && (
        <div className='fr'>
          <Pagination totalPages={pageCount} onPageSelect={changePage} key={pageCount} />
        </div>
      )}
    </>
  )
}

export default Datatable
