import { User as OidcUser } from 'oidc-client'

import { tokenAuth } from '../lib/axios'
import { Toggle } from '../models/Toggle'

export async function getToggles(oidcUser: OidcUser): Promise<Array<Toggle>> {
  const url = `${process.env.REACT_APP_BFF_BASE_URL}/feature-toggles`

  const authInstance = tokenAuth(oidcUser)

  const response = await authInstance.get<Array<Toggle>>(url)

  return response.data
}

export const formatTogglesForCsv = (displayedToggles: Array<Toggle>) => {
  const envName = process.env.REACT_APP_ENVIRONMENT ? process.env.REACT_APP_ENVIRONMENT.toUpperCase() : ''
  const currentDate = new Date().toLocaleDateString().split('T')[0]
  const envNameAndDate = `${envName}_${currentDate}`
  const header = ['"Toggle name"', '"Toggle value"', '"Toggle description"'].join(',')
  const csvString = displayedToggles
    .map((row) =>
      Object.values(row)
        .map((item) => {
          const value = item === true ? 'on' : item === false ? 'off' : item.toString()
          return `"${value.replace(/"/g, '""')}"`
        })
        .join(',')
    )
    .join('\n')

  return { header, csvString, envNameAndDate }
}

export const exportToggles = (displayedToggles: Array<Toggle>) => {
  const { header, csvString, envNameAndDate } = formatTogglesForCsv(displayedToggles)
  const csvData = new Blob([`${header}\n${csvString}`], { type: 'text/csv;charset=utf-8;' })
  const url = URL.createObjectURL(csvData)
  const link = document.createElement('a')
  link.href = url
  link.download = `Toggles_${envNameAndDate}.csv`
  link.click()
}
