import { User as OidcUser } from 'oidc-client'

import { tokenAuth } from '../lib/axios'
import Message, { EditableMessage, getMessageFromType, Status, stringToType, Type } from '../models/Message'

interface MessageResponse {
  applicationId: string
  id: string
  type: string
  startDateTime: string
  endDateTime: string
}

export const getDisruptionMessages = async (oidcUser: OidcUser): Promise<Array<Message>> => {
  const url = `${process.env.REACT_APP_BFF_BASE_URL}/disruption-messages`
  const authInstance = tokenAuth(oidcUser)

  const response = await authInstance.get<Array<MessageResponse>>(url)
  return response.data.map((message) => {
    const startDateTime = new Date(message.startDateTime)
    const endDateTime = new Date(message.endDateTime)
    const now = new Date()
    const status = startDateTime > now ? Status.SCHEDULED : endDateTime > now ? Status.ACTIVE : Status.INACTIVE
    const type = stringToType(message.type)

    return {
      applicationId: message.applicationId,
      id: message.id,
      type,
      message: getMessageFromType(type),
      startDateTime,
      endDateTime,
      status
    }
  })
}

interface CreateMessageParams {
  type: Type
  startDateTime: string
  endDateTime: string
}
interface EditMessageParams {
  alertId: string
  startDateTime: string
  endDateTime: string
}

export const createDisruptionMessage = async (oidcUser: OidcUser, params: CreateMessageParams): Promise<void> => {
  const url = `${process.env.REACT_APP_BFF_BASE_URL}/disruption-messages`
  const authInstance = tokenAuth(oidcUser)

  await authInstance.post(url, params)
}

export const editDisruptionMessage = async (oidcUser: OidcUser, body: EditMessageParams): Promise<void> => {
  const url = `${process.env.REACT_APP_BFF_BASE_URL}/disruption-messages`
  const authInstance = tokenAuth(oidcUser)
  await authInstance.patch(url, body)
}

export const getDisruptionMessage = async (oidcUser: OidcUser, alertId: string): Promise<EditableMessage> => {
  const url = `${process.env.REACT_APP_BFF_BASE_URL}/disruption-messages/${alertId}`
  const authInstance = tokenAuth(oidcUser)
  const response = await authInstance.get<MessageResponse>(url)
  const { id, type, startDateTime, endDateTime } = response.data
  return {
    id,
    type,
    startDateTime: new Date(startDateTime),
    endDateTime: new Date(endDateTime)
  }
}

export const deleteAlert = async (oidcUser: OidcUser, alertId: string): Promise<void> => {
  const url = `${process.env.REACT_APP_BFF_BASE_URL}/disruption-messages/${alertId}`
  const authInstance = tokenAuth(oidcUser)
  await authInstance.delete<Promise<void>>(url)
}
