import { User as OidcUser } from 'oidc-client'

import { tokenAuth } from '../lib/axios'
import { MaintenancePageResponse, MaintenancePageStatus } from '../models/MaintenanceStatus'

export const getMaintenancePageStatus = async (oidcUser: OidcUser): Promise<MaintenancePageResponse> => {
  const url = `${process.env.REACT_APP_BFF_BASE_URL}/maintenance`
  const authInstance = tokenAuth(oidcUser)

  const response = await authInstance.get<MaintenancePageResponse>(url)
  return response.data
}

export const postMaintenancePageStatus = (oidcUser: OidcUser, body: MaintenancePageStatus): Promise<void> => {
  const url = `${process.env.REACT_APP_BFF_BASE_URL}/maintenance`
  const authInstance = tokenAuth(oidcUser)

  return authInstance.post(url, body)
}
