import Right from './Right'

interface ConnectedUser {
  id: string
  rights: Array<Right>
}

export const defaultConnectedUser: ConnectedUser = {
  id: '',
  rights: []
}

export default ConnectedUser
