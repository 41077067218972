/* eslint-disable @typescript-eslint/no-empty-function */
import { ReactElement, useState } from 'react'
import DatePicker from 'react-datepicker'
import { useHistory } from 'react-router'
import { useReactOidc } from '@axa-fr/react-oidc-context'
import { Button, FormField, Icon, Label, Link, Spinner } from '@myeh/design-system'
import { format } from 'date-fns'

import 'react-datepicker/dist/react-datepicker.css'

import { getMessageFromType, stringToType, Type } from '../../../models/Message'
import { createDisruptionMessage } from '../../../services/PartialDisruptionMessageService'
import { PageTitle } from '../../atoms/PageTitle/PageTitle'
import { SimpleModal } from '../../molecules/SimpleModal/SimpleModal'

import style from './PartialDisruptionNewMessagePage.module.scss'

interface DropdownOption {
  value: string
  label: string
}
const dropdownTypes: Array<DropdownOption> = Object.values(Type).map((value) => ({
  value,
  label: value
}))

const apiEnvironmentMap: { [key: string]: string } = {
  uatm: 'uat-m',
  uatr: 'uat-r',
  prod: 'production',
  proddr: 'prod DR'
}
const getApiEnvironment = () => {
  const env = process.env.REACT_APP_API_ENVIRONMENT || ''
  return apiEnvironmentMap[env] || ''
}

export function PartialDisruptionNewMessagePage(): ReactElement {
  const { oidcUser } = useReactOidc()
  const [selectedType, setSelectedType] = useState<Type | string>('')
  const [startDate, setStartDate] = useState<Date | null>(new Date())
  const [endDate, setEndDate] = useState<Date | null>(null)
  const [errorSelectedType, setErrorSelectedType] = useState<string>('')
  const [errorStartDate, setErrorStartDate] = useState<string>('')
  const [errorEndDate, setErrorEndDate] = useState<string>('')
  const [openedModal, setOpenedModal] = useState<'success' | 'error' | 'validation' | 'none'>('none')
  const [loading, setLoading] = useState<boolean>(false)
  const history = useHistory()

  const onClickValidate = () => {
    let isInError = false
    if (!selectedType) {
      setErrorSelectedType('Please select a type. The type is mandatory to create a partial disruption message.')
      isInError = true
    }
    if (!startDate) {
      setErrorStartDate('Please define a start date and time. The start date and time are mandatory.')
      isInError = true
    }
    if (!endDate) {
      setErrorEndDate('Please define an end date and time. The end date and time are mandatory.')
      isInError = true
    }

    if (!startDate || !endDate) return

    const millisecondesStartDate = startDate.getTime()
    const millisecondesEndtDate = endDate.getTime()
    if (millisecondesStartDate >= millisecondesEndtDate) {
      setErrorEndDate('The end date and time must be greater than start date and time.')
      isInError = true
    }

    if (isInError) {
      return
    }

    setOpenedModal('validation')
  }

  async function handleContinueButton() {
    const params = {
      type: stringToType(selectedType),
      startDateTime: startDate?.toISOString() || '',
      endDateTime: endDate?.toISOString() || ''
    }
    setLoading(true)
    try {
      await createDisruptionMessage(oidcUser, params)
      setOpenedModal('success')
    } catch (e) {
      setOpenedModal('error')
    }
    setLoading(false)
  }

  const getDayMonthYear = (date: Date | null) => {
    if (!date) return ''
    return format(date, 'dd/MM/yyyy')
  }

  const getHoursMinutes = (date: Date | null) => {
    if (!date) return ''
    return format(date, 'HH:mm')
  }

  return (
    <div>
      <PageTitle title='New message' />
      <div className='mh4 mv2'>
        <Link href='/partial-disruption' inline icon='arrow-left' data-testid='back-to-list-link'>
          Back to list
        </Link>

        <h2 className={`${style.title} mt2`}>Partial disruption messages</h2>

        <div className='w-30 mt3'>
          <FormField.Dropdown
            placeholder='Select...'
            appearance='outline'
            name='message-type'
            label='Type'
            items={dropdownTypes}
            data-testid='message-type'
            onChange={(v) => {
              setSelectedType(v)
              setErrorSelectedType('')
            }}
          >
            {Boolean(errorSelectedType) && (
              <FormField.Error appearance='text' data-testid='message-type-error'>
                {errorSelectedType}
              </FormField.Error>
            )}
          </FormField.Dropdown>
        </div>

        {Boolean(selectedType) && (
          <div>
            <Label size='small'>Title</Label>
            <div className='mt2 mb3' data-testid='message-title'>
              Partial service disruption
            </div>

            <Label size='small'>Message</Label>
            <div className='mt2' data-testid='message-content'>
              {getMessageFromType(stringToType(selectedType))}
            </div>
          </div>
        )}

        <div className='mt3 w-30'>
          <DatePicker
            selected={startDate}
            onChange={(date) => {
              setStartDate(date)
              date && setErrorStartDate('')
            }}
            customInput={
              <FormField.Input name='start-date' appearance='outline' label='Start date and time'>
                <FormField.Suffix>
                  <Icon name='calendar' size='small' />
                </FormField.Suffix>
                <FormField.Hint>dd/mm/yyyy, hh:mm</FormField.Hint>
                {Boolean(errorStartDate) && (
                  <FormField.Error appearance='text' data-testid='start-date-error'>
                    {errorStartDate}
                  </FormField.Error>
                )}
              </FormField.Input>
            }
            showTimeSelect
            timeFormat='HH:mm'
            timeIntervals={15}
            timeCaption='time'
            dateFormat='dd/MM/yyyy, HH:mm'
            minDate={new Date()}
            popperClassName='z-9999'
          />
        </div>
        <div className='mt3 w-30'>
          <DatePicker
            selected={endDate}
            onChange={(date) => {
              setEndDate(date)
              date && setErrorEndDate('')
            }}
            customInput={
              <FormField.Input name='end-date' appearance='outline' label='End date and time' data-testid='end-date'>
                <FormField.Suffix>
                  <Icon name='calendar' size='small' />
                </FormField.Suffix>
                <FormField.Hint>dd/mm/yyyy, hh:mm</FormField.Hint>
                {Boolean(errorEndDate) && (
                  <FormField.Error appearance='text' data-testid='end-date-error'>
                    {errorEndDate}
                  </FormField.Error>
                )}
              </FormField.Input>
            }
            showTimeSelect
            timeFormat='HH:mm'
            timeIntervals={15}
            timeCaption='time'
            dateFormat='dd/MM/yyyy, HH:mm'
            minDate={new Date()}
            popperClassName='z-9999'
          />
        </div>

        <div className='flex justify-end mt4'>
          <Button
            size='small'
            variant='tertiary'
            className='mr3'
            onClick={() => history.push('/partial-disruption')}
            data-testid='cancel-button'
          >
            Cancel
          </Button>
          <Button size='small' variant='primary' onClick={onClickValidate} data-testid='validate-button'>
            Validate
          </Button>
        </div>
      </div>

      <SimpleModal
        open={openedModal === 'validation'}
        onClose={() => setOpenedModal('none')}
        title='Are you sure you want to schedule a partial disruption message?'
        body={`This partial disruption message is scheduled for MyEH ${getApiEnvironment()} environment starting ${getDayMonthYear(
          startDate
        )} at ${getHoursMinutes(startDate)} ending ${getDayMonthYear(endDate)} at ${getHoursMinutes(endDate)}.`}
        footer={
          <>
            <Button
              size='small-medium'
              variant='secondary'
              className='mr3'
              onClick={() => setOpenedModal('none')}
              disabled={loading}
              data-testid='dismiss-button-validation-modal'
            >
              No, dismiss
            </Button>
            <Button
              size='small-medium'
              variant='primary'
              onClick={() => handleContinueButton()}
              disabled={loading}
              data-testid='continue-button-validation-modal'
            >
              {loading ? <Spinner aria-label='spinner' size='medium' /> : 'Yes, continue'}
            </Button>
          </>
        }
        showCloseIcon={!loading}
        hideOnClickOutside={!loading}
        testId='validation-modal'
      />

      <SimpleModal
        open={openedModal === 'success'}
        onClose={() => setOpenedModal('none')}
        titleIcon='success'
        title='Partial disruption message is scheduled.'
        footer={
          <Button
            size='small-medium'
            variant='primary'
            onClick={() => {
              history.push('/partial-disruption')
            }}
            data-testid='ok-button-success-modal'
          >
            OK
          </Button>
        }
        testId='success-modal'
        showCloseIcon={false}
        hideOnClickOutside={false}
      />

      <SimpleModal
        open={openedModal === 'error'}
        onClose={() => setOpenedModal('none')}
        titleIcon='error'
        title='Partial disruption message schedule failed.'
        footer={
          <Button
            size='small-medium'
            variant='primary'
            onClick={() => setOpenedModal('none')}
            data-testid='ok-button-error-modal'
          >
            OK
          </Button>
        }
        testId='error-modal'
      />
    </div>
  )
}
