import { User as OidcUser } from 'oidc-client'

import { tokenAuth } from '../lib/axios'
import Right from '../models/Right'

export const getPermissions = async (oidcUser: OidcUser): Promise<Array<Right>> => {
  const url = `${process.env.REACT_APP_BFF_BASE_URL}/rights`
  const authInstance = tokenAuth(oidcUser)

  const response = await authInstance.get<Array<Right>>(url)
  return response.data
}
