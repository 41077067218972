/* eslint-disable @typescript-eslint/no-empty-function */
import { ReactElement, useState } from 'react'
import DatePicker from 'react-datepicker'
import { useHistory } from 'react-router'
import { useReactOidc } from '@axa-fr/react-oidc-context'
import { Button, FormField, Icon, Label, Spinner } from '@myeh/design-system'
import { format } from 'date-fns'

import 'react-datepicker/dist/react-datepicker.css'

import { getMessageFromType, stringToType } from '../../../models/Message'
import { editDisruptionMessage } from '../../../services/PartialDisruptionMessageService'
import { SimpleModal } from '../../molecules/SimpleModal/SimpleModal'

const apiEnvironmentMap: { [key: string]: string } = {
  uatm: 'uat-m',
  uatr: 'uat-r',
  prod: 'production',
  proddr: 'prod DR'
}

const getApiEnvironment = () => {
  const env = process.env.REACT_APP_API_ENVIRONMENT || ''
  return apiEnvironmentMap[env] || ''
}

export function EditPartialDisruptionMessageForm({
  startDateTime,
  endDateTime,
  id,
  type
}: {
  startDateTime: Date
  endDateTime: Date
  id: string
  type: string
}): ReactElement {
  const { oidcUser } = useReactOidc()
  const history = useHistory()
  const [startDate, setStartDate] = useState<Date | null>(startDateTime || null)
  const [endDate, setEndDate] = useState<Date | null>(endDateTime || null)
  const [errorStartDate, setErrorStartDate] = useState<string>('')
  const [errorEndDate, setErrorEndDate] = useState<string>('')
  const [openedModal, setOpenedModal] = useState<'success' | 'error' | 'validation' | 'none'>('none')
  const [loading, setLoading] = useState<boolean>(false)
  const now = new Date()
  const onClickValidate = () => {
    if (!startDate) {
      setErrorStartDate('Please define a start date and time. The start date and time are mandatory.')
      return
    }
    if (!endDate) {
      setErrorEndDate('Please define an end date and time. The end date and time are mandatory.')
      return
    }
    const millisecondesStartDate = startDate.getTime()
    const millisecondesEndtDate = endDate.getTime()
    if (millisecondesStartDate >= millisecondesEndtDate) {
      setErrorEndDate('The end date and time must be greater than start date and time.')
      return
    }
    setOpenedModal('validation')
  }

  const getDayMonthYear = (date: Date | null) => {
    if (!date) return ''
    return format(date, 'dd/MM/yyyy')
  }

  const getHoursMinutes = (date: Date | null) => {
    if (!date) return ''
    return format(date, 'HH:mm')
  }

  async function handleContinueButton() {
    if (!startDate || !endDate) return
    const params = {
      alertId: id,
      startDateTime: startDate.toISOString(),
      endDateTime: endDate.toISOString()
    }
    setLoading(true)
    try {
      await editDisruptionMessage(oidcUser, params)
      setOpenedModal('success')
    } catch (e) {
      setOpenedModal('error')
    }
    setLoading(false)
  }

  return (
    <div>
      <Label size='small'>Type</Label>
      <div className='mt2 mb3' data-testid='message-title'>
        {type}
      </div>
      <div>
        <Label size='small'>Title</Label>
        <div className='mt2 mb3' data-testid='message-title'>
          Partial service disruption
        </div>

        <Label size='small'>Message</Label>
        <div className='mt2' data-testid='message-content'>
          {getMessageFromType(stringToType(type))}
        </div>
      </div>
      <div className='mt3 w-30'>
        <DatePicker
          selected={startDate}
          onChange={(date) => {
            setStartDate(date)
            date && setErrorStartDate('')
          }}
          customInput={
            <FormField.Input name='start-date' appearance='outline' label='Start date and time'>
              <FormField.Suffix>
                <Icon name='calendar' size='small' />
              </FormField.Suffix>
              <FormField.Hint>dd/mm/yyyy, hh:mm</FormField.Hint>
              {Boolean(errorStartDate) && (
                <FormField.Error appearance='text' data-testid='start-date-error'>
                  {errorStartDate}
                </FormField.Error>
              )}
            </FormField.Input>
          }
          showTimeSelect
          timeFormat='HH:mm'
          timeIntervals={15}
          timeCaption='time'
          dateFormat='dd/MM/yyyy, HH:mm'
          minDate={now < startDateTime ? now : startDateTime}
          popperClassName='z-9999'
        />
      </div>
      <div className='mt3 w-30'>
        <DatePicker
          selected={endDate}
          onChange={(date) => {
            setEndDate(date)
            date && setErrorEndDate('')
          }}
          customInput={
            <FormField.Input name='end-date' appearance='outline' label='End date and time' data-testid='end-date'>
              <FormField.Suffix>
                <Icon name='calendar' size='small' />
              </FormField.Suffix>
              <FormField.Hint>dd/mm/yyyy, hh:mm</FormField.Hint>
              {Boolean(errorEndDate) && (
                <FormField.Error appearance='text' data-testid='end-date-error'>
                  {errorEndDate}
                </FormField.Error>
              )}
            </FormField.Input>
          }
          showTimeSelect
          timeFormat='HH:mm'
          timeIntervals={15}
          timeCaption='time'
          dateFormat='dd/MM/yyyy, HH:mm'
          minDate={now < endDateTime ? now : endDateTime}
          popperClassName='z-9999'
        />
      </div>
      <div className='flex justify-end mt4'>
        <Button
          size='small'
          variant='tertiary'
          className='mr3'
          onClick={() => history.push('/partial-disruption')}
          data-testid='cancel-button'
        >
          Cancel
        </Button>
        <Button size='small' variant='primary' onClick={onClickValidate} data-testid='validate-button'>
          Validate
        </Button>
      </div>

      <SimpleModal
        open={openedModal === 'validation'}
        onClose={() => setOpenedModal('none')}
        title='Are you sure you want to modify the schedule of this partial disruption message?'
        body={`This partial disruption message is scheduled for MyEH ${getApiEnvironment()} environment starting ${getDayMonthYear(
          startDate
        )} at ${getHoursMinutes(startDate)} ending ${getDayMonthYear(endDate)} at ${getHoursMinutes(endDate)}.`}
        footer={
          <>
            <Button
              size='small-medium'
              variant='secondary'
              className='mr3'
              onClick={() => setOpenedModal('none')}
              disabled={loading}
              data-testid='dismiss-button-validation-modal'
            >
              No, dismiss
            </Button>
            <Button
              size='small-medium'
              variant='primary'
              onClick={() => handleContinueButton()}
              disabled={loading}
              data-testid='continue-button-validation-modal'
            >
              {loading ? <Spinner aria-label='spinner' size='medium' /> : 'Yes, continue'}
            </Button>
          </>
        }
        showCloseIcon={!loading}
        hideOnClickOutside={!loading}
        testId='validation-modal'
      />
      <SimpleModal
        open={openedModal === 'success'}
        onClose={() => setOpenedModal('none')}
        titleIcon='success'
        title='Partial disruption message modification is confirmed'
        footer={
          <Button
            size='small-medium'
            variant='primary'
            onClick={() => {
              history.push('/partial-disruption')
            }}
            data-testid='ok-button-success-modal'
          >
            OK
          </Button>
        }
        testId='success-modal'
        showCloseIcon={false}
        hideOnClickOutside={false}
      />
      <SimpleModal
        open={openedModal === 'error'}
        onClose={() => setOpenedModal('none')}
        titleIcon='error'
        title='Partial disruption message schedule failed.'
        body='We are doing our best so you can schedule your message. Please try again later. '
        footer={
          <Button
            size='small-medium'
            variant='primary'
            onClick={() => setOpenedModal('none')}
            data-testid='ok-button-error-modal'
          >
            OK
          </Button>
        }
        testId='error-modal'
      />
    </div>
  )
}
