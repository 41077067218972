import jwtDecode from 'jwt-decode'
import { User as OidcUser } from 'oidc-client'

import User, { defaultConnectedUser } from '../models/ConnectedUser'
import Jwt from '../models/Jwt'
import Right from '../models/Right'
import { getPermissions } from './PermissionsService'
import queryClient from './QueryClient'

function getAccessTokenFromStorage(): string | null {
  for (let i = 0; i < window.sessionStorage.length; i++) {
    const key = window.sessionStorage.key(i) || ''
    // key has format oidc.user:{idpUrl}:{id}
    if (key.startsWith('oidc.user:')) {
      const oidcSessionJson = window.sessionStorage.getItem(key)
      if (!oidcSessionJson) {
        return null
      }
      const oidcSession = JSON.parse(oidcSessionJson)
      return oidcSession?.access_token
    }
  }
  return null
}

export async function getUser(oidcUser: OidcUser): Promise<User> {
  const token = getAccessTokenFromStorage()
  if (!token) {
    return defaultConnectedUser
  }
  const decoded = jwtDecode<Jwt>(token)

  let rights: Array<Right> = []
  try {
    rights = await queryClient.fetchQuery(['user-rights'], () => getPermissions(oidcUser))
  } catch (e) {
    console.error("Error while retrieving user's rights", e)
  }
  return {
    id: decoded?.external_id || '',
    rights: rights
  }
}
