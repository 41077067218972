import React from 'react'
import { Icon } from '@myeh/design-system'

import style from './InfoMessage.module.scss'

const infoMessageData = {
  error: {
    title: 'Technical error',
    text: (
      <>
        It seems we are currently facing an issue. <br /> We are doing our best so you can access your results.
      </>
    )
  },
  noData: {
    title: 'No results',
    text: 'We recommend you to modify your search criteria to find your internal user.'
  }
}

const InfoMessage = ({ isErrorMessage = false }) => {
  const icon = isErrorMessage ? (
    <Icon name='exclamation-circle-o' className={style.error} size='medium' data-testid='error-icon' />
  ) : (
    <Icon name='search' className={style.info} size='medium' data-testid='info-icon' />
  )

  const content = isErrorMessage ? infoMessageData.error : infoMessageData.noData

  return (
    <>
      {icon}
      <div className={style.title}>{content.title}</div>
      <div>{content.text}</div>
    </>
  )
}

export default InfoMessage
