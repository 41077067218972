import { useEffect, useState } from 'react'
import { QueryClientProvider } from 'react-query'
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom'
import { AuthenticationProvider, OidcSecure, useReactOidc } from '@axa-fr/react-oidc-context'
import { ThemeProvider } from '@myeh/design-system'

import '../../../node_modules/@myeh/design-system/dist/style.css'
import '../../../node_modules/@myeh/design-system/dist/fonts.css'
import '../../../node_modules/@myeh/design-system/dist/icons/style.css'

import { defaultConnectedUser } from '../../models/ConnectedUser'
import Right from '../../models/Right'
import { configuration as oidcConfiguration, loggerLevel } from '../../oidc/Configuration'
import { featureOverwrite, featureToggle } from '../../services/FeatureToggle'
import queryClient from '../../services/QueryClient'
import { getUser } from '../../services/UserService'
import ActAsHomePage from '../pages/ActAs/ActAsHomePage'
import { ContentPage } from '../pages/ContentPage/ContentPage'
import { EditPartialDisruptionMessagePage } from '../pages/EditPartialDisruptionMessagePage/EditPartialDisruptionMessagePage'
import { FeatureTogglesPage } from '../pages/FeatureTogglesPage/FeatureTogglesPage'
import { Homepage } from '../pages/Homepage/Homepage'
import { MaintenancePage } from '../pages/MaintenancePage/MaintenancePage'
import { MessagesPerBuPage } from '../pages/MessagesPerBuPage/MessagesPerBuPage'
import { PartialDisruptionNewMessagePage } from '../pages/PartialDisruptionNewMessagePage/PartialDisruptionNewMessagePage'
import { PartialDisruptionPage } from '../pages/PartialDisruptionPage/PartialDisruptionPage'

function Router() {
  const { oidcUser } = useReactOidc()

  const [user, setUser] = useState(defaultConnectedUser)
  useEffect(() => {
    getUser(oidcUser).then((user) => {
      setUser(user)
    })
  }, [oidcUser])

  return (
    <Switch>
      {featureToggle('tmp-homepage') ? (
        <Route exact path='/' component={Homepage} />
      ) : (
        <Redirect exact from='/' to='/act-as' />
      )}
      <Route exact path='/act-as' component={ActAsHomePage} />
      {featureToggle('tmp-maintenance-page') &&
        (user.rights.includes(Right.MAINTENANCE_READ) || user.rights.includes(Right.MAINTENANCE_WRITE)) && (
          <Route exact path='/maintenance' component={MaintenancePage} />
        )}
      {featureToggle('tmp-feature-toggles') &&
        (user.rights.includes(Right.TOGGLES_READ) || user.rights.includes(Right.TOGGLES_WRITE)) && (
          <Route exact path='/feature-toggles' component={FeatureTogglesPage} />
        )}
      {featureToggle('tmp-partial-disruption') &&
        (user.rights.includes(Right.DISRUPTION_READ) || user.rights.includes(Right.DISRUPTION_WRITE)) && (
          <Route exact path='/partial-disruption' component={PartialDisruptionPage} />
        )}
      {featureToggle('tmp-partial-disruption') && user.rights.includes(Right.DISRUPTION_WRITE) && (
        <Route exact path='/partial-disruption/new-message' component={PartialDisruptionNewMessagePage} />
      )}
      {featureToggle('tmp-partial-disruption') && user.rights.includes(Right.DISRUPTION_WRITE) && (
        <Route exact path='/partial-disruption/edit-message/:id' component={EditPartialDisruptionMessagePage} />
      )}
      {featureToggle('tmp-messages-per-bu') && user.rights.includes(Right.DISRUPTION_READ) && (
        <Route exact path='/messages-per-bu' component={MessagesPerBuPage} />
      )}
    </Switch>
  )
}

function App() {
  featureOverwrite()
  const isAuthentEnabled = !(process.env.REACT_APP_IS_AUTHENT === 'false')

  return (
    <AuthenticationProvider isEnabled={isAuthentEnabled} configuration={oidcConfiguration} loggerLevel={loggerLevel}>
      <OidcSecure>
        <ThemeProvider withGlobalStyle={false}>
          <QueryClientProvider client={queryClient}>
            <BrowserRouter>
              <ContentPage>
                <Router />
              </ContentPage>
            </BrowserRouter>
          </QueryClientProvider>
        </ThemeProvider>
      </OidcSecure>
    </AuthenticationProvider>
  )
}

export default App
