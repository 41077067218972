import { oidcLog } from '@axa-fr/react-oidc-context'

const appUrl = window.location.origin

export const configuration = {
  authority: `${process.env.REACT_APP_API_BASE_URL}/v1/oidc`,
  client_id: process.env.REACT_APP_CLIENT_ID,
  response_type: 'code',
  authorization_code: 'grant_type',
  scope: 'openid',
  post_logout_redirect_uri: `${appUrl}`,
  redirect_uri: `${appUrl}/authentication/callback`,
  silent_redirect_uri: `${appUrl}/authentication/silent_callback`,
  automaticSilentRenew: true,
  loadUserInfo: true,
  revokeAccessTokenOnSignout: true
}

type oidcLogLevel = 0 | 1 | 2 | 3 | 4

const loggerLevelAsString: string = process.env.REACT_APP_OIDC_LOGGER_LEVEL || '4'
export const loggerLevel: oidcLogLevel = (parseInt(loggerLevelAsString, 10) as oidcLogLevel) || oidcLog.DEBUG
