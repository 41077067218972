import React, { useCallback, useEffect, useState } from 'react'
import { useReactOidc } from '@axa-fr/react-oidc-context'
import { Button, ErrorMessage, FormField, Grid, Spinner } from '@myeh/design-system'

import { getBusinessUnits } from '../../../services/BusinessUnitsService'

import style from './AdditionalContactInformation.module.scss'

export const dropdownEmptyValue = ' '
const emptyOption = { value: dropdownEmptyValue, label: dropdownEmptyValue }

const userTypeList = [
  emptyOption,
  { value: 'CLIENT', label: 'Client' },
  { value: 'INTERMEDIARY', label: 'Intermediary' }
]

// to uncomment and move to tsx when the FormField.Dropdown types are fixed in the design system (value is missing)
// type AdditionalContactInformationProps = {
//   setBuCode: (x: string) => void
//   buCode: string
//   setUserType: (x: string) => void
//   userType: string
//   className?: string
// }
const AdditionalContactInformation = ({ setBuCode, buCode, setUserType, userType, className }) => {
  const { oidcUser } = useReactOidc()
  const [loading, setLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const [buCodeOptions, setBuCodeOptions] = useState([])
  const fetchBusinessUnits = useCallback(async () => {
    setLoading(true)
    setIsError(false)
    try {
      const { data } = await getBusinessUnits(oidcUser)
      const options = data
        .map(({ businessUnitCode, businessUnitLabel }) => ({
          value: businessUnitCode,
          label: `${businessUnitCode} (${businessUnitLabel})`
        }))
        .sort((a, b) => a > b)
      setBuCodeOptions([emptyOption, ...options])
      setLoading(false)
      setIsError(false)
    } catch (e) {
      setLoading(false)
      setIsError(true)
    }
  }, [oidcUser])

  useEffect(() => {
    fetchBusinessUnits()
  }, [fetchBusinessUnits])

  return (
    <div className={className}>
      <div>
        Please select the 'Business Unit Code' and/or 'User Type' of the above Contact ID to retrieve all related
        features (optional).
      </div>
      <Grid className={style.grid}>
        <Grid.Row>
          <Grid.Column size='12,8,6,4'>
            {loading ? (
              <div className={style.cell}>
                <Spinner aria-label='spinner' size='medium' />
              </div>
            ) : (
              <>
                <FormField.Dropdown
                  data-testid='bu-code'
                  name='buCode'
                  appearance='outline'
                  label='Business Unit Code (optional)'
                  placeholder='SFAC (AT France)'
                  floatLabel='always'
                  value={buCode}
                  defaultValue={buCode}
                  onChange={setBuCode}
                  isSearchable
                  items={buCodeOptions}
                  disabled={isError}
                />
                {isError && (
                  <>
                    <ErrorMessage>
                      <div>
                        <b>Technical Error</b>
                      </div>
                      It seems we are currently facing an issue retrieving the list of business units. <br /> Please try
                      again later.
                    </ErrorMessage>
                    <Button
                      variant='secondary'
                      size='x-small'
                      danger
                      className='mt3'
                      onClick={() => fetchBusinessUnits()}
                    >
                      Retry
                    </Button>
                  </>
                )}
              </>
            )}
          </Grid.Column>
          <Grid.Column size='12,8,6,3'>
            <FormField.Dropdown
              data-testid='user-type'
              name='userType'
              appearance='outline'
              label='User Type (optional)'
              placeholder='Client'
              floatLabel='always'
              value={userType}
              defaultValue={userType}
              onChange={setUserType}
              items={userTypeList}
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </div>
  )
}

export default AdditionalContactInformation
