import { AxiosResponse } from 'axios'
import { User as OidcUser } from 'oidc-client'

import { tokenAuth } from '../lib/axios'
import BusinessUnit from '../models/BusinessUnit'

export const getBusinessUnits = (oidcUser: OidcUser): Promise<AxiosResponse<BusinessUnit[]>> => {
  const url = `${process.env.REACT_APP_API_BASE_URL}/v1/businessUnits?isInsuredBusinessUnit=true`

  const authInstance = tokenAuth(oidcUser)

  return authInstance.get(url)
}
