import { ReactElement, useEffect, useState } from 'react'
import { useReactOidc } from '@axa-fr/react-oidc-context'

import { defaultConnectedUser } from '../../../models/ConnectedUser'
import Right from '../../../models/Right'
import { featureToggle } from '../../../services/FeatureToggle'
import { getUser } from '../../../services/UserService'
import { PageTitle } from '../../atoms/PageTitle/PageTitle'
import { QuickLink } from '../../molecules/QuickLink/QuickLink'

import style from './Homepage.module.scss'

export function Homepage(): ReactElement {
  const { oidcUser } = useReactOidc()

  const [user, setUser] = useState(defaultConnectedUser)
  useEffect(() => {
    getUser(oidcUser).then((user) => {
      setUser(user)
    })
  }, [oidcUser])

  return (
    <div>
      <PageTitle title='Home' />
      <div className='pa4'>
        <div className={style.intro} data-testid='home-intro'>
          Welcome to MyEH Admin portal. You are connected as "{user.id}".
        </div>
        <QuickLink
          url='/act-as'
          title='Act-as'
          icon='user-business-o'
          description='Act as another user.'
          data-testid='home-act-as'
        />
        {featureToggle('tmp-maintenance-page') &&
          (user.rights.includes(Right.MAINTENANCE_READ) || user.rights.includes(Right.MAINTENANCE_WRITE)) && (
            <QuickLink
              url='/maintenance'
              title='Maintenance page'
              icon='product-excavator'
              description='Review the position of the maintenance page.'
              className='mt3'
              data-testid='home-maintenance'
            />
          )}
        {featureToggle('tmp-feature-toggles') &&
          (user.rights.includes(Right.TOGGLES_READ) || user.rights.includes(Right.TOGGLES_WRITE)) && (
            <QuickLink
              url='/feature-toggles'
              title='Feature toggles'
              icon='product-to-do-list'
              description='Review the list of existing toggles and their position.'
              className='mt3'
              data-testid='home-feature-toggles'
            />
          )}
        {featureToggle('tmp-partial-disruption') &&
          (user.rights.includes(Right.DISRUPTION_READ) || user.rights.includes(Right.DISRUPTION_WRITE)) && (
            <QuickLink
              url='/partial-disruption'
              title='Partial disruption messages'
              icon='product-bullhorn'
              description='Review the list of partial disruption messages.'
              className='mt3'
              data-testid='home-partial-disruption'
            />
          )}
        {featureToggle('tmp-messages-per-bu') &&
          (user.rights.includes(Right.DISRUPTION_READ) || user.rights.includes(Right.DISRUPTION_WRITE)) && (
            <QuickLink
              url='/messages-per-bu'
              title='Messages per BU'
              icon='mail-o'
              description='Review the list of active messages per BU.'
              className='mt3'
              data-testid='home-messages-per-bu'
            />
          )}
      </div>
    </div>
  )
}
