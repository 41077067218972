import React, { ReactElement } from 'react'
import { Icon, Modal } from '@myeh/design-system'

import style from './SimpleModal.module.scss'

export function SimpleModal({
  open,
  onClose,
  titleIcon,
  title,
  body,
  footer,
  hideOnClickOutside,
  showCloseIcon,
  testId
}: {
  open: boolean
  onClose: () => void
  titleIcon?: 'error' | 'success'
  title: string
  body?: string
  footer: ReactElement
  hideOnClickOutside?: boolean
  showCloseIcon?: boolean
  testId?: string
}): ReactElement {
  return (
    <Modal
      open={open}
      onClose={onClose}
      hideOnClickOutside={hideOnClickOutside}
      showCloseIcon={showCloseIcon}
      data-testid={testId}
    >
      <div className='flex mb3'>
        {titleIcon === 'success' && <Icon name='check-circle' className={`${style.iconSuccess} mr2`} size='small' />}
        {titleIcon === 'error' && (
          <Icon name='exclamation-triangle' className={`${style.iconError} mr2`} size='small' />
        )}
        <h2 className={`mv0 ${style.title}`}>{title}</h2>
      </div>
      {body && <div>{body}</div>}
      <div className='flex justify-end mt4'>{footer}</div>
    </Modal>
  )
}
