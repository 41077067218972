import React, { useState } from 'react'
import { Button, Card, FormField, Label } from '@myeh/design-system'

import style from './SearchBar.module.scss'

const numberOfDigits = 12

const SearchBar = ({ setSearchQuery }) => {
  const [contactId, setContactId] = useState('')

  function handleSubmit(event) {
    event.preventDefault()
    if (contactId) {
      setSearchQuery(contactId)
      setContactId('')
    }
  }

  return (
    <div>
      <Label className='subtitle'>Search a user</Label>
      <Card className={style.card}>
        <form className={style.form} onSubmit={handleSubmit}>
          <FormField.Input
            data-testid='contact-id'
            name='contactId'
            appearance='outline'
            label='Contact ID'
            typ='text'
            placeholder={`${numberOfDigits} digits max`}
            floatLabel='always'
            maxLength={numberOfDigits}
            value={contactId}
            onChange={(event) => setContactId(event.target.value)}
          />
          <Button
            variant='primary'
            type='submit'
            size='small'
            data-testid='search-button'
            disabled={!contactId}
            className={style.button}
          >
            Search
          </Button>
        </form>
      </Card>
    </div>
  )
}

export default SearchBar
