import { ReactElement, useEffect, useMemo, useState } from 'react'
import { useHistory } from 'react-router'
import { Column } from 'react-table'
import { useReactOidc } from '@axa-fr/react-oidc-context'
import {
  Badge,
  Button,
  FormField,
  Icon,
  IconButton,
  RadioToggle,
  RadioToggleButton,
  Spinner
} from '@myeh/design-system'

import { defaultConnectedUser } from '../../../models/ConnectedUser'
import Message, { Status, Type } from '../../../models/Message'
import Right from '../../../models/Right'
import { deleteAlert, getDisruptionMessages } from '../../../services/PartialDisruptionMessageService'
import { getUser } from '../../../services/UserService'
import { formatDateTime } from '../../../utils/date'
import { PageTitle } from '../../atoms/PageTitle/PageTitle'
import Datatable from '../../molecules/Datatable/Datatable'
import { SimpleModal } from '../../molecules/SimpleModal/SimpleModal'

import style from './PartialDisruptionPage.module.scss'

interface DropdownOption {
  value: string
  label: string
}

const dropdownTypes: Array<DropdownOption> = Object.values(Type).map((value) => ({
  value,
  label: value
}))
dropdownTypes.unshift({
  value: '*',
  label: 'All'
})

export function PartialDisruptionPage(): ReactElement {
  const { oidcUser } = useReactOidc()
  const history = useHistory()

  const [user, setUser] = useState(defaultConnectedUser)
  const [openedModal, setOpenedModal] = useState<'success' | 'error' | 'validation' | 'none'>('none')
  const [loadingDeleteMessage, setLoadingDeleteMessage] = useState<boolean>(false)
  const [disruptionMessageId, setDisruptionMessageId] = useState<string>('')
  useEffect(() => {
    async function assignUser() {
      const user = await getUser(oidcUser)
      setUser(user)
    }
    assignUser()
  }, [oidcUser])
  const canWrite = useMemo(() => !user.rights.includes(Right.DISRUPTION_WRITE), [user])

  const [disruptionMessages, setDisruptionMessages] = useState<Array<Message>>([])
  const [shouldReload, setShouldReload] = useState<boolean>(true)
  const [loading, setLoading] = useState<boolean>(true)
  useEffect(() => {
    async function assignDisruptionMessages() {
      try {
        setDisruptionMessages(await getDisruptionMessages(oidcUser))
      } catch (e) {}
      setLoading(false)
    }

    if (shouldReload) {
      setDisruptionMessages([])
      setLoading(true)
      setShouldReload(false)
      assignDisruptionMessages()
    }
  }, [oidcUser, shouldReload])

  const [statusFilter, setStatusFilter] = useState('')
  const filterByStatus = (value: string) => {
    setStatusFilter(value)
  }

  const [typeFilter, setTypeFilter] = useState<Type | string>()
  const filterByType = (value: Type | string) => {
    setTypeFilter(value)
  }

  const filteredMessages = useMemo<Array<Message>>(() => {
    return disruptionMessages.filter(
      (message) =>
        (statusFilter === '' || message.status === statusFilter) &&
        (!typeFilter || typeFilter === '*' || message.type === typeFilter)
    )
  }, [disruptionMessages, statusFilter, typeFilter])

  const editDisruptionMessage = (disruptionMessage: Message) => {
    if (history) {
      history.push(`/partial-disruption/edit-message/${disruptionMessage.id}`)
    }
  }

  const setValidationDeleteDisruptionMessage = (disruptionMessage: Message) => {
    setDisruptionMessageId(disruptionMessage.id)
    setOpenedModal('validation')
  }

  async function handleDeleteDisruptionMessage() {
    setLoadingDeleteMessage(true)
    try {
      await deleteAlert(oidcUser, disruptionMessageId)
      setOpenedModal('success')
      setShouldReload(true)
    } catch (e) {
      setOpenedModal('error')
    }
    setLoadingDeleteMessage(false)
  }

  // columns definition for DataTable
  const columns: Array<Column<Message>> = [
    {
      Header: 'Type',
      accessor: 'type',
      disableSortBy: true
    },
    {
      Header: 'Message content',
      accessor: 'message',
      Cell: ({ value }) => (
        <>
          <b>Partial service disruption</b>
          <div className='mt3'>{value}</div>
        </>
      ),
      disableSortBy: true
    },
    {
      Header: 'Start date',
      accessor: 'startDateTime',
      Cell: ({ value }) => <>{formatDateTime(value)}</>,
      sortType: (rowA, rowB) => {
        return rowA.original.startDateTime.getTime() - rowB.original.startDateTime.getTime()
      }
    },
    {
      Header: 'End date',
      accessor: 'endDateTime',
      Cell: ({ value }) => <>{value ? formatDateTime(value) : '-'}</>,
      sortType: (rowA, rowB) => {
        return rowA.original.startDateTime.getTime() - rowB.original.startDateTime.getTime()
      }
    },
    {
      Header: 'Status',
      accessor: 'status',
      disableSortBy: true,
      Cell: ({ value }) => {
        const statusToBadge = (status: Status): 'default' | 'active' | 'positive' => {
          switch (status) {
            case Status.ACTIVE:
              return 'positive'
            case Status.SCHEDULED:
              return 'active'
            default:
              return 'default'
          }
        }
        return <Badge type={statusToBadge(value)}>{value}</Badge>
      }
    },
    {
      Header: 'Actions',
      accessor: 'id',
      disableSortBy: true,
      Cell: ({ row: { original } }) => {
        return (
          <div className='flex'>
            <IconButton
              size='small'
              disabled={canWrite}
              onClick={() => editDisruptionMessage(original)}
              className='mr1'
              data-testid={`${original.id}-edit`}
            >
              <Icon name='pen' size='small' />
            </IconButton>
            <IconButton
              variant='secondary'
              size='small'
              disabled={canWrite}
              onClick={() => setValidationDeleteDisruptionMessage(original)}
              data-testid={`${original.id}-delete`}
            >
              <Icon name='trash' size='small' />
            </IconButton>
          </div>
        )
      }
    }
  ]

  return (
    <div>
      <PageTitle title='Partial disruption messages' />
      <div className='pa4'>
        <div className='pb4'>
          On this page you will find the list of existing partial disruption messages for the environment you are
          connected to.
          <br />
          Those messages allow you to alert your users of a partial disruption of the services.
        </div>
        {loading ? (
          <div className={style.spinner}>
            <Spinner size='large' />
          </div>
        ) : (
          <>
            <RadioToggle name='status-filter' toggleStyle='small' onSelect={filterByStatus} horizontal className='pv2'>
              <RadioToggleButton value=''>All ({disruptionMessages.length})</RadioToggleButton>
              <RadioToggleButton value={Status.ACTIVE}>
                {Status.ACTIVE} ({disruptionMessages.filter((message) => message.status === Status.ACTIVE).length})
              </RadioToggleButton>
              <RadioToggleButton value={Status.SCHEDULED}>
                {Status.SCHEDULED} ({disruptionMessages.filter((message) => message.status === Status.SCHEDULED).length}
                )
              </RadioToggleButton>
              <RadioToggleButton value={Status.INACTIVE}>
                {Status.INACTIVE} ({disruptionMessages.filter((message) => message.status === Status.INACTIVE).length})
              </RadioToggleButton>
            </RadioToggle>
            <div className='flex items-center justify-between w-100'>
              <div className='w-25'>
                <FormField.Dropdown name='type-filter' label='Type' items={dropdownTypes} onChange={filterByType} />
              </div>
              <Button
                size='small'
                disabled={canWrite}
                onClick={() => history.push('/partial-disruption/new-message')}
                data-testid='new-message-button'
              >
                New message
              </Button>
            </div>
            <Datatable<Message> data={filteredMessages} columns={columns} isSortable isPaginable />
          </>
        )}
      </div>
      <SimpleModal
        open={openedModal === 'validation'}
        onClose={() => setOpenedModal('none')}
        title='Are you sure you want to delete this partial disruption message?'
        footer={
          <>
            <Button
              size='small-medium'
              variant='secondary'
              className='mr3'
              onClick={() => setOpenedModal('none')}
              disabled={loadingDeleteMessage}
              data-testid='dismiss-button-validation-modal'
            >
              No, dismiss
            </Button>
            <Button
              size='small-medium'
              variant='primary'
              onClick={() => handleDeleteDisruptionMessage()}
              disabled={loadingDeleteMessage}
              data-testid='continue-button-validation-modal'
            >
              {loadingDeleteMessage ? <Spinner aria-label='spinner' size='medium' /> : 'Yes, continue'}
            </Button>
          </>
        }
        showCloseIcon={!loading}
        hideOnClickOutside={!loading}
        testId='validation-modal'
      />

      <SimpleModal
        open={openedModal === 'success'}
        onClose={() => setOpenedModal('none')}
        titleIcon='success'
        title='Partial disruption message is deleted.'
        footer={
          <Button
            size='small-medium'
            variant='primary'
            onClick={() => {
              setOpenedModal('none')
            }}
            data-testid='ok-button-success-modal'
          >
            OK
          </Button>
        }
        testId='success-modal'
      />

      <SimpleModal
        open={openedModal === 'error'}
        onClose={() => setOpenedModal('none')}
        titleIcon='error'
        title='Partial disruption message deletion failed.'
        body='Please try again.'
        footer={
          <Button
            size='small-medium'
            variant='primary'
            onClick={() => setOpenedModal('none')}
            data-testid='ok-button-error-modal'
          >
            OK
          </Button>
        }
        testId='error-modal'
      />
    </div>
  )
}
