import { User as OidcUser } from 'oidc-client'

import { tokenAuth } from '../lib/axios'
import Message, { getMessageFromType, Status, stringToType } from '../models/Message'

interface MessageResponse {
  applicationId: string
  id: string
  type: string
  startDateTime: string
  endDateTime: string
  hasFallback: boolean
  buCode: string
}

export const getMessagesPerBU = async (oidcUser: OidcUser): Promise<Array<Message>> => {
  const url = `${process.env.REACT_APP_BFF_BASE_URL}/messages-per-bu`
  const authInstance = tokenAuth(oidcUser)

  const response = await authInstance.get<Array<MessageResponse>>(url)
  return response.data.map((message) => {
    const startDateTime = new Date(message.startDateTime)
    const endDateTime = message.endDateTime ? new Date(message.endDateTime) : undefined
    const now = new Date()
    const status =
      startDateTime > now ? Status.SCHEDULED : !endDateTime || endDateTime > now ? Status.ACTIVE : Status.INACTIVE
    const type = stringToType(message.type)

    return {
      applicationId: message.applicationId,
      id: message.id,
      type,
      message: getMessageFromType(type),
      startDateTime,
      endDateTime,
      status,
      hasFallback: message.hasFallback,
      buCode: message.buCode
    }
  })
}
