import { ReactElement } from 'react'
import { Link } from 'react-router-dom'
import { Card, Icon, IconName } from '@myeh/design-system'

import style from './QuickLink.module.scss'

interface Props {
  icon: IconName
  title: string
  url: string
  description: string
  className?: string
  'data-testid'?: string
}

export const QuickLink = ({ icon, title, description, url, className, 'data-testid': testId }: Props): ReactElement => {
  return (
    <Link to={url} className={style.link} data-testid={testId}>
      <Card className={`${style.card} ${className}`}>
        <Icon size='large' name={icon} className={style.icon} />
        <div className='ml3'>
          <h3 className={style.title}>{title}</h3>
          <div className={`mt1 ${style.titleSeparator}`} />
          <p className={`mt3 ${style.description}`}>{description}</p>
        </div>
      </Card>
    </Link>
  )
}
