import axios, { AxiosInstance } from 'axios'
import { User as OidcUser } from 'oidc-client'
import { v4 as uuidv4 } from 'uuid'

import { generateEhTraceId } from './tracing'

export function tokenAuth(oidcUser: OidcUser): AxiosInstance {
  const authAxios = axios.create({
    headers: {
      Authorization: `Bearer ${oidcUser && oidcUser.access_token}`,
      'Content-Type': 'application/json; charset=utf-8',
      Accept: 'application/json, text/plain, */*',
      'X-Request-Id': uuidv4(),
      'X-EH-Trace-Id': generateEhTraceId(),
      'X-Request-Start': Date.now()
    }
  })

  return authAxios
}
