import { ReactElement, useEffect, useMemo, useState } from 'react'
import { Column } from 'react-table'
import { useReactOidc } from '@axa-fr/react-oidc-context'
import { Badge, Button, Icon, IconButton, Spinner } from '@myeh/design-system'

import { defaultConnectedUser } from '../../../models/ConnectedUser'
import Message, { Status, Type } from '../../../models/Message'
import Right from '../../../models/Right'
import { getMessagesPerBU } from '../../../services/MessagesPerBUService'
import { deleteAlert } from '../../../services/PartialDisruptionMessageService'
import { getUser } from '../../../services/UserService'
import { formatDateTime } from '../../../utils/date'
import { PageTitle } from '../../atoms/PageTitle/PageTitle'
import Datatable from '../../molecules/Datatable/Datatable'
import { SimpleModal } from '../../molecules/SimpleModal/SimpleModal'

import style from './MessagesPerBuPage.module.scss'

interface DropdownOption {
  value: string
  label: string
}

const dropdownTypes: Array<DropdownOption> = Object.values(Type).map((value) => ({
  value,
  label: value
}))
dropdownTypes.unshift({
  value: '*',
  label: 'All'
})

export function MessagesPerBuPage(): ReactElement {
  const { oidcUser } = useReactOidc()

  const [user, setUser] = useState(defaultConnectedUser)
  const [messages, setMessages] = useState<Array<Message>>([])
  const [loading, setLoading] = useState<boolean>(true)
  const [openedModal, setOpenedModal] = useState<'success' | 'error' | 'validation' | 'none'>('none')
  const [loadingDeleteMessage, setLoadingDeleteMessage] = useState<boolean>(false)
  const [messageId, setMessageId] = useState<string>('')
  const [shouldReload, setShouldReload] = useState<boolean>(true)
  useEffect(() => {
    async function assignUser() {
      const user = await getUser(oidcUser)
      setUser(user)
    }
    assignUser()
  }, [oidcUser])
  const canWrite = useMemo(() => !user.rights.includes(Right.DISRUPTION_WRITE), [user])
  useEffect(() => {
    async function assignMessages() {
      try {
        setMessages(await getMessagesPerBU(oidcUser))
      } catch (e) {}
      setLoading(false)
    }

    if (shouldReload) {
      setMessages([])
      setLoading(true)
      setShouldReload(false)
      assignMessages()
    }
  }, [oidcUser, shouldReload])

  const setValidationDeleteMessage = (message: Message) => {
    setMessageId(message.id)
    setOpenedModal('validation')
  }

  async function handleDeleteMessage() {
    setLoadingDeleteMessage(true)
    try {
      await deleteAlert(oidcUser, messageId)
      setOpenedModal('success')
      setShouldReload(true)
    } catch (e) {
      setOpenedModal('error')
    }
    setLoadingDeleteMessage(false)
  }

  // columns definition for DataTable
  const columns: Array<Column<Message>> = [
    {
      Header: 'Alert ID',
      accessor: 'id',
      disableSortBy: true
    },
    {
      Header: 'BU',
      accessor: 'buCode',
      disableSortBy: true
    },
    {
      Header: 'Start date',
      accessor: 'startDateTime',
      Cell: ({ value }) => <>{formatDateTime(value)}</>,
      sortType: (rowA, rowB) => {
        return rowA.original.startDateTime.getTime() - rowB.original.startDateTime.getTime()
      }
    },
    {
      Header: 'End date',
      accessor: 'endDateTime',
      Cell: ({ value }) => <>{value ? formatDateTime(value) : '-'}</>,
      sortType: (rowA, rowB) => {
        return rowA.original.startDateTime.getTime() - rowB.original.startDateTime.getTime()
      }
    },
    {
      Header: 'Status',
      accessor: 'status',
      disableSortBy: true,
      Cell: ({ value }) => {
        const statusToBadge = (status: Status): 'default' | 'active' | 'positive' => {
          switch (status) {
            case Status.ACTIVE:
              return 'positive'
            case Status.SCHEDULED:
              return 'active'
            default:
              return 'default'
          }
        }
        return <Badge type={statusToBadge(value)}>{value}</Badge>
      }
    },
    {
      Header: 'Fallback to en-GB',
      accessor: 'hasFallback',
      disableSortBy: true,
      Cell: ({ value }) => {
        if (value) return <Badge type='positive'>Present</Badge>
        return <Badge type='critical'>Missing</Badge>
      }
    },
    {
      Header: 'Actions',
      accessor: 'applicationId',
      disableSortBy: true,
      Cell: ({ row: { original } }) => {
        return (
          <div className='flex'>
            <IconButton
              variant='secondary'
              size='small'
              disabled={canWrite}
              onClick={() => setValidationDeleteMessage(original)}
              data-testid={`${original.id}-delete`}
            >
              <Icon name='trash' size='small' />
            </IconButton>
          </div>
        )
      }
    }
  ]

  return (
    <div>
      <PageTitle title='Messages per BU' />
      <div className='pa4'>
        <div className='pb4'>
          On this page you will find the list of active messages per BU for the environment you are connected to.
        </div>
        {loading ? (
          <div className={style.spinner}>
            <Spinner size='large' />
          </div>
        ) : (
          <Datatable<Message> data={messages} columns={columns} isSortable isPaginable />
        )}
      </div>
      <SimpleModal
        open={openedModal === 'validation'}
        onClose={() => setOpenedModal('none')}
        title='Are you sure you want to delete this message?'
        footer={
          <>
            <Button
              size='small-medium'
              variant='secondary'
              className='mr3'
              onClick={() => setOpenedModal('none')}
              disabled={loadingDeleteMessage}
              data-testid='dismiss-button-validation-modal'
            >
              No, dismiss
            </Button>
            <Button
              size='small-medium'
              variant='primary'
              onClick={() => handleDeleteMessage()}
              disabled={loadingDeleteMessage}
              data-testid='continue-button-validation-modal'
            >
              {loadingDeleteMessage ? <Spinner aria-label='spinner' size='medium' /> : 'Yes, continue'}
            </Button>
          </>
        }
        showCloseIcon={!loading}
        hideOnClickOutside={!loading}
        testId='validation-modal'
      />

      <SimpleModal
        open={openedModal === 'success'}
        onClose={() => setOpenedModal('none')}
        titleIcon='success'
        title='Message was successfully deleted.'
        footer={
          <Button
            size='small-medium'
            variant='primary'
            onClick={() => {
              setOpenedModal('none')
            }}
            data-testid='ok-button-success-modal'
          >
            OK
          </Button>
        }
        testId='success-modal'
      />

      <SimpleModal
        open={openedModal === 'error'}
        onClose={() => setOpenedModal('none')}
        titleIcon='error'
        title='Message deletion failed.'
        body='Please try again.'
        footer={
          <Button
            size='small-medium'
            variant='primary'
            onClick={() => setOpenedModal('none')}
            data-testid='ok-button-error-modal'
          >
            OK
          </Button>
        }
        testId='error-modal'
      />
    </div>
  )
}
