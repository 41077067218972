import qs from 'qs'

const featureToggle = (flagName: string): boolean => window._features && window._features[flagName]

const featureOverwrite = (): void => {
  const queryParams = qs.parse(window.location.search, {
    ignoreQueryPrefix: true
  })
  if (queryParams) {
    for (const [key, value] of Object.entries(queryParams)) {
      if (window._features && window._features[key] !== undefined) {
        window._features[key] = value === 'true'
      }
    }
  }
}

export { featureToggle, featureOverwrite }
